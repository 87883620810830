import $ from 'jquery'
window.jQuery = $;
window.$ = $;

import 'velocity-animate'
import 'velocity-ui-pack'
import 'slick-carousel'
import 'jquery.easing'
import 'bootstrap'

// CSS imports
import '../node_modules/slick-carousel/slick/slick.css'
import '../node_modules/bootstrap/dist/css/bootstrap.min.css'
import '../scss/app.scss'
import '../scss/style.scss'
import './app.js'

$(document).ready(function() {

  $(".spotlight__slider").slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: false,
    arrows: true,
    infinite: false
  });

  $(".clients__slider").slick({
    slidesToShow: 4,
    slidesToScroll: 4,
    dots: false,
    arrows: true,
    infinite: false,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  });

  // Closes responsive menu when a scroll trigger link is clicked
  $('.js-scroll-trigger').click(function() {
    $('.navbar-collapse').collapse('hide');
  });

// Activate scrollspy to add active class to navbar items on scroll

  var body = $('body');
  if (body.length) {
    $('body').scrollspy({
      target: '.header__wrapper',
      offset: 78
    });
  }


  $('a.js-scroll-trigger[href*="#"]:not([href="#"])').click(function() {
    if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
      var target = $(this.hash);
      target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
      if (target.length) {
        $('html, body').animate({
          scrollTop: (target.offset().top - 54)
        }, 1000, "easeInOutExpo");
        return false;
      }
    }
  });
}())
